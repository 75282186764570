@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* add the code bellow */ 
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

body {
  @apply leading-[normal] m-0;
}

html, body {
  overscroll-behavior: none;
  height: 100%;
  overflow: hidden; /* Prevent scrolling */
}